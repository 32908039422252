import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import getCroppedImage from './cropImage';
import { getBranding, updateBranding } from '../../api';
import UploadForm from './components/UploadForm';
import LogoPreview from './components/LogoPreview';
import {
  Box,
  Button,
  TextField,
  FormLabel,
  FormControl,
  Typography,
  Divider,
} from '@mui/material';

function ElementsWrapper({ fullWidth, children }) {
  return (
    <Box sx={{ mb: 3 }}>
      <FormControl sx={{ maxWidth: fullWidth ? '100%' : 400 }} fullWidth>
        {children}
      </FormControl>
    </Box>
  );
}

function BrandingPage() {
  const [imageIsUploading, setImageIsUploading] = React.useState(false);
  const [uploadedImage, setUploadedImage] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);

  const [name, setName] = React.useState('');
  const [url, setUrl] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [logoAttached, setLogoAttached] = React.useState(null);
  const [currentLogoUrl, setCurrentLogoUrl] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getBranding().then((response) => {
      if (response.data) {
        const { name, url, address, logoUrl } = response.data.attributes;

        setName(name);
        setUrl(url);
        setAddress(address);
        setLogoAttached(logoUrl);
        setCurrentLogoUrl(logoUrl);
      }
    });
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    let croppedImage = '';
    const formData = new FormData();

    if (uploadedImage) {
      croppedImage = await getCroppedImage(uploadedImage, croppedAreaPixels);
      croppedImage.lastModifiedDate = new Date();
      croppedImage.name = 'image.png';

      // Do not rewrite uploaded image after save if there is no uploadedImage
      formData.append('branding[logo]', croppedImage);
    }

    // Logo was deleted
    if (!logoAttached && !croppedImage) {
      formData.append('branding[logo]', croppedImage);
    }

    formData.append('branding[name]', name);
    formData.append('branding[url]', url);
    formData.append('branding[address]', address);

    setImageIsUploading(true);

    updateBranding(formData)
      .then(() => {
        enqueueSnackbar('Changes has been saved!', { variant: 'success' });

        if (croppedImage) {
          setLogoAttached(true);
          setCurrentLogoUrl(URL.createObjectURL(croppedImage));
        }

        setErrors({});
      })
      .catch((e) => {
        const catchedErrors = JSON.parse(e.message).errors;
        setErrors({
          ...catchedErrors,
        });
      })
      .finally(() => {
        setUploadedImage(null);
        setImageIsUploading(false);
      });
  };

  return (
    <>
      <ElementsWrapper>
        <FormLabel>Display name</FormLabel>
        <TextField
          value={name}
          data-testid="BrandingPage/NameInput"
          maxLength="255"
          name="branding[name]"
          onChange={(e) => setName(e.target.value)}
          error={errors.name && errors.name[0]}
          helperText={errors.name && errors.name[0]}
        />
      </ElementsWrapper>
      <ElementsWrapper>
        <FormLabel>Clickthru url</FormLabel>
        <TextField
          value={url}
          data-testid="BrandingPage/UrlInput"
          name="branding[url]"
          onChange={(e) => setUrl(e.target.value)}
          error={errors.url && errors.url[0]}
          helperText={errors.url && errors.url[0]}
        />
      </ElementsWrapper>
      <ElementsWrapper fullWidth>
        {logoAttached ? (
          <>
            <FormLabel>Logo / Favicon</FormLabel>
            <LogoPreview
              setLogoAttached={setLogoAttached}
              logoUrl={currentLogoUrl}
              title={name}
            />
          </>
        ) : (
          <>
            <FormLabel>Logo / Favicon</FormLabel>
            <UploadForm
              uploadedImage={uploadedImage}
              setUploadedImage={setUploadedImage}
              setCroppedAreaPixels={setCroppedAreaPixels}
              error={errors.logo && errors.logo[0]}
            />
          </>
        )}
      </ElementsWrapper>
      <Divider sx={{ my: 4 }} />
      <Typography mb={1} variant="h4">
        Workspace address
      </Typography>
      <Typography sx={{ mt: 1, mb: 2 }} variant="subtitle1">
        This address will be used in your email and Formli templates.
      </Typography>
      <ElementsWrapper>
        <FormLabel>Address</FormLabel>
        <TextField
          value={address}
          data-testid="BrandingPage/AddressInput"
          name="branding[address]"
          onChange={(e) => setAddress(e.target.value)}
          error={errors.address && errors.address[0]}
          helperText={errors.address && errors.address[0]}
        />
      </ElementsWrapper>
      <Button
        aria-label="Save"
        data-testid="BrandingPage/SubmitButton"
        onClick={handleFormSubmit}
        disabled={imageIsUploading}
        variant="outlined"
      >
        Save Changes
      </Button>
    </>
  );
}

export default BrandingPage;
