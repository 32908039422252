import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Divider,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TemplatesList from './components/TemplatesList';
import { getEmailTemplates } from '../../api';
import { Label } from '../../globals';

const TYPE_CUSTOM = 'CustomEmail';
const TYPE_WORKSPACE = 'WorkspaceEmailTemplate';
const TYPE_PRODUCT = 'ProductEmailTemplate';

const listMapper = {
  product: TYPE_PRODUCT,
  workspace: TYPE_WORKSPACE,
};

function ChooseEmailTemplateModal({ variant, isOpen, hideModal, onContinue }) {
  if (!isOpen) {
    return null;
  }

  const [templates, setTemplates] = React.useState({
    [TYPE_CUSTOM]: [],
    [TYPE_WORKSPACE]: [],
    [TYPE_PRODUCT]: [],
  });
  const [selectedTemplate, setSelectedTemplate] = React.useState({
    id: 'new',
    type: TYPE_CUSTOM,
  });

  const handleContinue = () => {
    onContinue(
      templates[selectedTemplate.type].find(
        (t) => t.id === selectedTemplate.id,
      ),
    );
    hideModal();
  };

  React.useEffect(() => {
    getEmailTemplates().then(({ data }) => {
      const sortedTemplates = data.reduce(
        (acc, obj) => {
          const type = obj.attributes.emailTemplatableType;
          acc[type] = [...acc[type], obj.attributes];

          return acc;
        },
        {
          [TYPE_CUSTOM]: [],
          [TYPE_WORKSPACE]: [],
          [TYPE_PRODUCT]: [],
        },
      );
      setTemplates(sortedTemplates);
    });
  }, []);

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root.MuiDialog-paper': {
          width: '100%',
          maxWidth: 614,
          minHeight: 510,
        },
      }}
      open={isOpen}
      onClose={hideModal}
    >
      <DialogTitle sx={{ px: 0, fontSize: 16, textAlign: 'center' }}>
        Choose a template
        <IconButton
          aria-label="close"
          onClick={hideModal}
          sx={{
            position: 'absolute',
            width: 'auto',
            right: 8,
            top: 8,
            '&:hover': {
              color: 'inherit',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider sx={{ mt: 2 }} />
      </DialogTitle>
      <DialogContent sx={{ p: 2, maxHeight: 500 }}>
        <Typography sx={{ fontSize: 14 }} component="p">
          Select a template
        </Typography>
        <Typography sx={{ opacity: 0.5, fontSize: 12, mb: 2 }} component="p">
          Start from scratch or reuse your past templates
        </Typography>
        {templates[listMapper[variant]].length > 0 && (
          <>
            <Box sx={{ mt: 2 }}>
              <Label>Templates</Label>
            </Box>
            <Divider sx={{ my: 2 }} />
            <TemplatesList
              items={templates[listMapper[variant]]}
              selected={selectedTemplate.id}
              setSelected={(id) =>
                setSelectedTemplate({ id, type: listMapper[variant] })
              }
            />
          </>
        )}
        {templates[TYPE_CUSTOM].length > 0 && (
          <>
            <Box sx={{ mt: 2 }}>
              <Label>Your past emails</Label>
            </Box>
            <Divider sx={{ my: 2 }} />
            <TemplatesList
              items={templates[TYPE_CUSTOM]}
              selected={selectedTemplate.id}
              setSelected={(id) =>
                setSelectedTemplate({ id, type: TYPE_CUSTOM })
              }
            />
          </>
        )}
      </DialogContent>
      <Divider sx={{ mt: 2 }} />
      <DialogActions sx={{ p: 2 }}>
        <Button
          variant="contained"
          sx={{
            width: 110,
            textTransform: 'unset',
            bgcolor: '#ff4200',
            '&:hover': {
              bgcolor: '#cc3500',
            },
          }}
          disableRipple
          onClick={handleContinue}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ChooseEmailTemplateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default ChooseEmailTemplateModal;
